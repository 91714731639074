import { useNavigation } from '@react-navigation/native';
import {
  Platform,
  StyleSheet,
  Text,
  TextInput,
  ScrollView,
  View,
} from 'react-native';
import { Tab, TabView } from '@rneui/themed';
import { StatusBar } from 'expo-status-bar';
import { useEffect, useState } from 'react';
import QRCode from 'react-native-qrcode-svg';
import Button from '../components/Button';
import ButtonBar from '../components/ButtonBar';
import { API_URL } from '@env';
import { Formik } from 'formik';
import * as Yup from 'yup';

const Board = ({ loggedUser, editMode = false, updateBoard, route }) => {
  const navigation = useNavigation();

  const [index, setIndex] = useState(0);

  const [edit, setEdit] = useState(editMode);

  const [data, setData] = useState({
    name: 'Loading...',
    description: '',
  });

  const validate = Yup.object().shape({
    name: Yup.string().required('Required'),
  });

  useEffect(() => {
    const loadBoard = async (boardId) => {
      const url = API_URL + '/.netlify/functions/boards/' + boardId;
      await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          //'x-access-token': loggedUser.token,
        },
      })
        .then(async (response) => {
          if (response.ok) {
            const responseJson = await response.json();
            if (responseJson) {
              setData(responseJson);
            }
          } else {
            setData({
              name: 'Board not found',
              description: '',
            });
            console.log('Error: ' + JSON.stringify(data));
          }
        })
        .catch((e) => {
          setData({
            name: "Can't connect to server.",
            description: '',
          });
          console.log('Error:' + JSON.stringify(e.message));
        });
    };
    if (!edit) {
      loadBoard(route.params.id);
    }
  }, [route]);

  const saveBoard = async (values) => {
    const url = API_URL + '/.netlify/functions/boards';
    const boardValues = {
      id: route.params.id,
      name: values.name,
      description: values.description,
    };
    const response = await fetch(url, {
      method: 'put',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': loggedUser.token,
      },
      body: JSON.stringify(boardValues),
    }).catch((e) => {
      console.log('Error:' + JSON.stringify(e.message));
    });
    if (response.status === 200) {
      setEdit(false);
      setData(boardValues); // copy data from form to state
      if (updateBoard) {
        updateBoard(boardValues);
      }
    } else {
      console.log('Error ' + response.status);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: data.name,
        description: data.description,
      }}
      validateOnChange={false}
      validationSchema={validate}
      onSubmit={saveBoard}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        values,
        errors,
        isValid,
      }) => (
        <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
          <View style={styles.mainContainer}>
            <Tab
              value={index}
              onChange={(e) => setIndex(e)}
              indicatorStyle={{
                backgroundColor: 'white',
                height: 3,
                width: '25%', //hack to fix indicator issue at iOS
              }}
              variant="primary"
            >
              <Tab.Item
                title={Platform.OS === 'web' ? 'Info' : ''}
                icon={{
                  name: 'md-information-circle-outline',
                  type: 'ionicon',
                  color: 'white',
                }}
              />
              <Tab.Item
                title={Platform.OS === 'web' ? 'Images' : ''}
                icon={{
                  name: 'md-image-outline',
                  type: 'ionicon',
                  color: 'white',
                }}
              />
              <Tab.Item
                title={Platform.OS === 'web' ? 'Comments' : ''}
                icon={{
                  name: 'message1',
                  type: 'antdesign',
                  color: 'white',
                }}
              />
              <Tab.Item
                title={Platform.OS === 'web' ? 'Settings' : ''}
                icon={{
                  name: 'md-settings-outline',
                  type: 'ionicon',
                  color: 'white',
                }}
              />
            </Tab>
            <TabView value={index} onChange={setIndex} animationType="spring">
              <TabView.Item style={styles.tab}>
                <>
                  <View style={styles.frame}>
                    <View style={styles.content}>
                      {!edit && (
                        <>
                          {!!data.name && (
                            <Text style={{ fontSize: 28 }}>{data.name}</Text>
                          )}
                          {!!data.description && (
                            <Text>{data.description}</Text>
                          )}
                        </>
                      )}
                      {edit && (
                        <>
                          <TextInput
                            style={{ fontSize: 28 }}
                            onChangeText={handleChange('name')}
                            onBlur={handleBlur('name')}
                            value={values.name}
                            placeholder="Board Name"
                          />
                          <View style={{ marginBottom: 20 }}>
                            {errors.name && touched.name ? (
                              <Text style={{ color: 'red', fontSize: 15 }}>
                                {errors.name}
                              </Text>
                            ) : null}
                          </View>
                          <TextInput
                            onChangeText={handleChange('description')}
                            onBlur={handleBlur('description')}
                            value={values.description}
                            multiline={true}
                            numberOfLines={10}
                            placeholder="Board Description"
                          />
                          <View style={{ marginBottom: 20 }}>
                            {errors.description && touched.description ? (
                              <Text style={{ color: 'red', fontSize: 15 }}>
                                {errors.description}
                              </Text>
                            ) : null}
                          </View>
                        </>
                      )}
                    </View>
                  </View>
                </>
              </TabView.Item>
              <TabView.Item style={styles.tab}>
                <Text>Images</Text>
              </TabView.Item>
              <TabView.Item style={styles.tab}>
                <Text>TODO - Messages</Text>
              </TabView.Item>
              <TabView.Item style={styles.tab}>
                {data.id && (
                  <View style={styles.admin}>
                    <Text>Share this boar with QR</Text>
                    <QRCode
                      value={API_URL + '/b/' + data.id}
                      //logo={{ uri: base64Logo }}
                      logoSize={30}
                      logoBackgroundColor="transparent"
                      size={200}
                    />
                  </View>
                )}
              </TabView.Item>
            </TabView>

            <ButtonBar>
              {!edit && (
                <Button
                  title="Edit"
                  disabled={!loggedUser}
                  onPress={() => setEdit(true)}
                />
              )}
              {edit && (
                <Button
                  title="Save"
                  disabled={!loggedUser}
                  onPress={handleSubmit}
                />
              )}
              {edit && (
                <Button
                  title="Cancel"
                  disabled={!loggedUser}
                  onPress={() => setEdit(false)}
                />
              )}
              <Button
                title={navigation.canGoBack() ? 'Back' : 'Close'}
                disabled={edit}
                onPress={() =>
                  navigation.canGoBack()
                    ? navigation.goBack()
                    : navigation.reset({
                        index: 0,
                        routes: [{ name: 'Home' }],
                      })
                }
              />
            </ButtonBar>
            <StatusBar style="auto" />
          </View>
        </ScrollView>
      )}
    </Formik>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    height: '100%',
  },
  frame: {
    flex: 7,
    alignItems: 'center',
    width: '100%',
    borderWidth: 5,
    borderRadius: 30,
    padding: 10,
    backgroundColor: '#A47449',
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  content: {
    flex: 1,
    alignItems: 'center',
    width: '100%',
    borderWidth: 5,
    borderRadius: 20,
    padding: 10,
    backgroundColor: 'lightblue',
    height: '100%',
  },
  admin: {
    paddingTop: 20,
    flex: 3,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  tab: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export default Board;
