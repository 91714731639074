import { useEffect, useState } from 'react';
import { Text, View, Platform } from 'react-native';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { useNavigation } from '@react-navigation/native';
import Button from '../components/Button';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import ButtonBar from '../components/ButtonBar';

const Scanner = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();

  const [allowScan, setAllowScan] = useState(Platform.OS !== 'web');
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [scan, setScan] = useState(null);
  const [scanError, setScanError] = useState(false);

  useEffect(() => {
    const getBarCodeScannerPermissions = async () => {
      const { status } = await BarCodeScanner.requestPermissionsAsync();
      setHasPermission(status === 'granted');
    };

    if (allowScan) {
      getBarCodeScannerPermissions();
    }
  }, []);

  const handleBarCodeScanned = ({ type, data }) => {
    const prefix = 'https://boardito.online/b/';
    if (data.startsWith(prefix)) {
      setScanned(true);
      setScan(data.slice(prefix.length));
      setScanError(false);
    } else {
      setScanError(true);
      setTimeout(() => setScanError(false), 2000);
    }
  };

  if (allowScan) {
    if (hasPermission === null) {
      return <Text>Requesting for camera permission</Text>;
    }
    if (hasPermission === false) {
      return (
        <Text>No access to camera. Please enable camera in settings.</Text>
      );
    }
  }

  return (
    <View
      style={{
        flex: 1,
        flexGrow: 1,
        justifyContent: 'space-evenly',
        width: '100%',
      }}
    >
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        {!scanned && <Text>Scan Boardito QR code.</Text>}
        {scanned && <Text>Continue to Boardito id {scan}?</Text>}
      </View>
      <View style={{ flex: 1 }}>
        <ButtonBar>
          {allowScan && scanned && (
            <Button
              title={'Use'}
              onPress={() => navigation.navigate('Board', { id: scan })}
            />
          )}
          <Button
            title={'Cancel'}
            onPress={() => navigation.navigate('Home')}
          />
          {allowScan && scanned && (
            <Button title={'Scan Again'} onPress={() => setScanned(false)} />
          )}
        </ButtonBar>
      </View>
      <View style={{ flex: 5, flexDirection: 'row', justifyContent: 'center' }}>
        {allowScan && !scanned && (
          <BarCodeScanner
            onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
            style={{
              width: 400,
              height: 400,
            }}
          >
            {scanError && (
              <View
                style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                  }}
                >
                  This is not Boardito QR code
                </Text>
              </View>
            )}
          </BarCodeScanner>
        )}
      </View>
    </View>
  );
};

export default Scanner;
