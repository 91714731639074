import 'react-native-gesture-handler';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Platform, View } from 'react-native';
import { useEffect, useState } from 'react';

import Home from './screens/Home';
import Scanner from './screens/Scanner';
import Board from './screens/Board';
import User from './screens/User';
import RegisterBoard from './screens/RegisterBoard';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ResetPassword from './screens/ResetPassword';
import ChangePassword from './screens/ChangePassword';

const App = () => {
  const Stack = createStackNavigator();

  const [loggedUser, setLoggedUser] = useState(null);
  useEffect(() => {
    const loadUser = async () => {
      const loggedInUser = await loadUserData();
      if (loggedInUser !== null) {
        setLoggedUser(loggedInUser);
      }
    };
    loadUser();
  }, []);

  const storeUserData = async (userData) => {
    await AsyncStorage.setItem('_user', JSON.stringify(userData));
  };

  const loadUserData = async () => {
    return JSON.parse(await AsyncStorage.getItem('_user'));
  };

  const clearUserData = async () => {
    await storeUserData(null);
  };

  const config = {
    screens: {
      Board: '/b/:id',
      ChangePassword: 'change-password',
    },
  };
  const linking = {
    prefixes: ['https://boardito.online'], //TODO investigate usage of 'boardito://',
    config,
  };

  const [boards, setBoards] = useState([]);

  const updateBoard = (board) => {
    const boardList = boards;
    const idx = boardList.indexOf(boardList.find((b) => b.id === board.id));
    if (idx !== -1) {
      boardList[idx].name = board.name;
      boardList[idx].description = board.description;
      setBoards(boardList);
    }
  };

  const importFonts = () => {
    return Platform.OS === 'web' ? (
      <style type="text/css">{`
      @font-face {
        font-family: 'Ionicons';
        src: url(${require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Ionicons.ttf')}) format('truetype');
      }

      @font-face {
        font-family: 'antdesign';
        src: url(${require('@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/AntDesign.ttf')}) format('truetype');
      }
    `}</style>
    ) : (
      <></>
    );
  };

  return (
    <>
      {importFonts()}
      <SafeAreaProvider>
        <View style={{ flex: 1 }}>
          <NavigationContainer linking={linking}>
            <Stack.Navigator
              initialRouteName="Home"
              screenOptions={{
                headerShown: true,
              }}
            >
              <Stack.Screen name="Home" component={Home} />
              <Stack.Screen name="Scanner" component={Scanner} />
              <Stack.Screen name="Board">
                {(props) => (
                  <Board
                    loggedUser={loggedUser}
                    updateBoard={updateBoard}
                    {...props}
                  />
                )}
              </Stack.Screen>
              <Stack.Screen name="RegisterBoard">
                {(props) => (
                  <RegisterBoard loggedUser={loggedUser} {...props} />
                )}
              </Stack.Screen>
              <Stack.Screen name="User">
                {(props) => (
                  <User
                    loggedUser={loggedUser}
                    setLoggedUser={setLoggedUser}
                    storeUserData={storeUserData}
                    clearUserData={clearUserData}
                    boards={boards}
                    setBoards={setBoards}
                    {...props}
                  />
                )}
              </Stack.Screen>
              <Stack.Screen
                name="ResetPassword"
                component={ResetPassword}
                options={{ title: 'Reset password' }}
              />
              <Stack.Screen
                name="ChangePassword"
                component={ChangePassword}
                options={{ title: 'Change password' }}
              />
            </Stack.Navigator>
          </NavigationContainer>
        </View>
      </SafeAreaProvider>
    </>
  );
};

export default App;
