import { useNavigation } from '@react-navigation/native';
import { Dimensions, StyleSheet, Text, TextInput, View } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../components/Button';
import ButtonBar from '../components/ButtonBar';
import { API_URL } from '@env';

const RegisterBoard = ({ loggedUser }) => {
  const navigation = useNavigation();

  const validateCreate = Yup.object().shape({
    name: Yup.string().required('Required'),
  });

  const registerBoard = async (values) => {
    const url = API_URL + '/.netlify/functions/boards';
    const response = await fetch(url, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': loggedUser.token,
      },
      body: JSON.stringify({
        name: values.name,
        description: values.description,
      }),
    }).catch((e) => {
      console.log('Error:' + JSON.stringify(e.message));
    });
    if (response.status === 200) {
      const responseJson = await response.json();
      navigation.navigate('Board', { id: responseJson.id });
    } else {
      console.log('Error ' + response.status);
    }
  };

  return (
    <View
      style={{
        height: Dimensions.get('window').height * 0.8,
      }}
    >
      <Formik
        initialValues={{
          name: '',
          description: '',
        }}
        validateOnChange={false}
        validationSchema={validateCreate}
        onSubmit={registerBoard}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          values,
          errors,
          isValid,
        }) => (
          <View
            style={{
              flex: 1,
              // alignSelf: 'center',
              justifyContent: 'flex-start',
              alignItems: 'center',
              padding: 20,
            }}
          >
            <Text
              style={{
                paddingBottom: 20,
              }}
            >
              Enter name of your board and add description. Print QR code which
              will be generated after you click on Create button. Place QR code
              to your real board and add same content to your virtual board.
            </Text>
            <View>
              <TextInput
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: '#808080',
                  alignItems: 'center',
                }}
                onChangeText={handleChange('name')}
                onBlur={handleBlur('name')}
                value={values.name}
                placeholder="Board Name"
              />
              <View style={{ marginBottom: 20 }}>
                {errors.name && touched.name ? (
                  <Text style={{ color: 'red', fontSize: 15 }}>
                    {errors.name}
                  </Text>
                ) : null}
              </View>
            </View>
            <View>
              <TextInput
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: '#808080',
                  alignItems: 'center',
                }}
                onChangeText={handleChange('description')}
                onBlur={handleBlur('description')}
                value={values.description}
                multiline={true}
                numberOfLines={10}
                placeholder="Board Description"
              />
              <View style={{ marginBottom: 20 }}>
                {errors.description && touched.description ? (
                  <Text style={{ color: 'red', fontSize: 15 }}>
                    {errors.description}
                  </Text>
                ) : null}
              </View>
            </View>
            <ButtonBar>
              <Button
                title="Create"
                onPress={handleSubmit}
                disabled={!loggedUser}
              />
              <Button
                title={navigation.canGoBack() ? 'Back' : 'Close'}
                onPress={() =>
                  navigation.canGoBack()
                    ? navigation.goBack()
                    : navigation.reset({ index: 0, routes: [{ name: 'Home' }] })
                }
              />
            </ButtonBar>
            {!loggedUser && (
              <Text
                onPress={() => navigation.navigate('User')}
                style={styles.ref}
              >
                Please log in to create new board.
              </Text>
            )}
          </View>
        )}
      </Formik>
      <StatusBar style="auto" />
    </View>
  );
};

const styles = StyleSheet.create({
  ref: {
    color: 'blue',
    textDecorationColor: 'blue',
    textDecorationLine: 'underline',
  },
});

export default RegisterBoard;
