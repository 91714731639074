import { useIsFocused, useNavigation } from '@react-navigation/native';
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

const BoardList = ({ loggedUser, boards }) => {
  const navigation = useNavigation();

  // force rerender during back navigation - when board is edit on Stack Navigator
  const isFocused = useIsFocused();

  return (
    isFocused && (
      <View style={styles.container}>
        <Text style={styles.caption}>Boards</Text>
        <View style={styles.scrollWrap}>
          <ScrollView style={styles.scroll} contentContainerStyle={{ flex: 0 }}>
            <View style={styles.boardList}>
              <TouchableOpacity
                key="_new_board"
                onPress={() => navigation.navigate('RegisterBoard')}
              >
                <View style={styles.boarItemNew}>
                  <Text style={styles.title}>Add new</Text>
                </View>
              </TouchableOpacity>
              {boards.map((board) => (
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate('Board', {
                      id: board.id,
                      loggedUser: loggedUser,
                    });
                  }}
                  key={board._id}
                >
                  <View style={styles.boardItem}>
                    <Text style={styles.title}>{board.name}</Text>
                    <Text>{board.description}</Text>
                  </View>
                </TouchableOpacity>
              ))}
              {
                /* lame workaround to align items centered and flex-start */
                Array(10)
                  .fill()
                  .map((_, index) => (
                    <Text
                      key={'fill_' + index}
                      style={styles.boarItemFill}
                    ></Text>
                  ))
              }
            </View>
          </ScrollView>
        </View>
      </View>
    )
  );
};

const BOX_SIZE = Platform.OS === 'ios' ? 160 : 200;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  caption: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 0.25,
    height: 21,
  },
  scrollWrap: {
    height: 'auto',
    overflow: 'hidden', // TODO test if it is necessary on iOS
  },
  scroll: {},
  boardList: {
    flex: 1,
    flexGrow: 1,
    flexShrink: 0, // TODO test if it is necessary on iOS
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    rowGap: 20,
    columnGap: 20,
    height: '100%', // TODO test if it is necessary on iOS
  },
  boardItem: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderWidth: 3,
    borderRadius: 30,
    borderColor: '#A47449',
    padding: 20,
    width: BOX_SIZE,
    height: BOX_SIZE,
    minWidth: BOX_SIZE,
    minHeight: BOX_SIZE,
    maxWidth: BOX_SIZE,
    maxHeight: BOX_SIZE,
    overflow: 'hidden',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  boarItemNew: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 4,
    borderRadius: 30,
    borderColor: '#A47449',
    borderStyle: 'dotted',
    padding: 20,
    minWidth: BOX_SIZE,
    minHeight: BOX_SIZE,
  },
  boarItemFill: {
    minWidth: BOX_SIZE,
    minHeight: 1,
  },
});

export default BoardList;
