import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import defaultTranslator from 'jwt-auth-mongodb/dist/fe/util/defaultTranslator';
import { isWeb } from 'jwt-auth-mongodb/dist/fe/util/util';
import Button from '../components/Button';
import ButtonBar from '../components/ButtonBar';
import { API_URL } from '@env';
import { useNavigation } from '@react-navigation/native';
import { changePassword } from 'jwt-auth-mongodb/dist/fe/util/auth';

const ChangePassword = ({ t = defaultTranslator }) => {
  const navigation = useNavigation();
  const [changePasswordError, setChangePasswordError] = useState(null);

  const validationSignIn = Yup.object().shape({
    email: Yup.string()
      .email('components.user.emailInvalid')
      .required(t('components.authForm.required')),
    resetCode: Yup.string().required(t('components.authForm.required')),
    newPassword: Yup.string().required(t('components.authForm.required')),
  });

  const changePasswordHandler = async (values) => {
    try {
      const userData = await changePassword(
        values.email,
        values.resetCode.trim(),
        values.newPassword,
        setChangePasswordError,
        API_URL,
        t
      );
      if (userData) {
        setChangePasswordError('Password changed.');
        console.log('Password changed.');
        //TODO link to redirect or redirect after x seconds
        navigation.navigate('User');
      } else {
        setChangePasswordError(t('lib.auth.changePassword.cantChangePassword'));
        return false;
      }
    } catch (e) {
      setChangePasswordError(t('lib.auth.changePassword.cantChangePassword'));
      return false;
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        resetCode: '',
        newPassword: '',
      }}
      validateOnChange={false}
      validationSchema={validationSignIn}
      onSubmit={changePasswordHandler}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        values,
        errors,
      }) => (
        <View style={styles.container}>
          <View style={styles.form}>
            <View style={styles.fieldView}>
              <View style={styles.fieldWrapper}>
                {isWeb() && <Text>{t('components.authForm.email')}</Text>}
                <TextInput
                  style={styles.input}
                  onBlur={handleBlur('email')}
                  value={values.email}
                  placeholder="Enter email"
                  placeholderTextColor={isWeb() ? 'black' : 'white'}
                  autoComplete="off"
                  onChangeText={handleChange('email')}
                />
              </View>
              {errors.email && touched.email ? (
                <Text style={styles.errorMessage}>{errors.email}</Text>
              ) : null}
            </View>
            <View style={styles.fieldView}>
              <View style={styles.fieldWrapper}>
                {isWeb() && <Text>{t('components.authForm.resetCode')}</Text>}
                <TextInput
                  style={styles.input}
                  onBlur={handleBlur('resetCode')}
                  value={values.resetCode}
                  placeholder="Reset Code from email"
                  placeholderTextColor={isWeb() ? 'black' : 'white'}
                  autoComplete="off"
                  onChangeText={handleChange('resetCode')}
                />
              </View>
              {errors.resetCode && touched.resetCode ? (
                <Text style={styles.errorMessage}>{errors.resetCode}</Text>
              ) : null}
            </View>
            <View style={styles.fieldView}>
              <View style={styles.fieldWrapper}>
                {isWeb() && <Text>{t('components.authForm.newPassword')}</Text>}
                <TextInput
                  style={styles.input}
                  secureTextEntry={true}
                  onBlur={handleBlur('newPassword')}
                  value={values.newPassword}
                  placeholder="New password"
                  placeholderTextColor={isWeb() ? 'black' : 'white'}
                  autoComplete="off"
                  onChangeText={handleChange('newPassword')}
                />
              </View>
              {errors.newPassword && touched.newPassword ? (
                <Text style={styles.errorMessage}>{errors.newPassword}</Text>
              ) : null}
            </View>
            <View style={styles.buttonArea}>
              {changePasswordError && (
                <Text style={styles.errorMessage}>{changePasswordError}</Text>
              )}
              <ButtonBar>
                <Button
                  title={t('components.authForm.changePassword')}
                  onPress={handleSubmit}
                />
              </ButtonBar>
            </View>
          </View>
        </View>
      )}
    </Formik>
  );
};

const styles = isWeb()
  ? StyleSheet.create({
      container: {
        flex: 1,
        alignItems: 'center',
        height: '100%',
      },
      form: {
        maxWidth: 600,
        height: '100%',
      },
      fieldView: {},
      fieldWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 20,
      },
      input: {
        borderWidth: 1,
        marginLeft: 20,
        paddingLeft: 5,
      },
      buttonArea: {
        flexDirection: 'column',
        paddingTop: 20,
      },
      errorMessage: {
        color: 'red',
        textAlign: 'center',
      },
    })
  : // mobile styles
    StyleSheet.create({
      container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      form: {
        width: '100%',
        flex: 1,
      },
      fieldView: {
        width: '100%',
        alignItems: 'center',
      },
      fieldWrapper: {
        backgroundColor: '#3BF',
        borderRadius: 30,
        width: '70%',
        height: 45,
        marginTop: 20,

        alignItems: 'center',
      },
      input: {
        height: 50,
        flex: 1,
        padding: 10,
        textAlign: 'center',
        width: '100%',
        color: 'white',
      },
      buttonArea: {
        flexDirection: 'column',
        paddingTop: 20,
      },
      errorMessage: {
        color: 'red',
        textAlign: 'center',
      },
    });

export default ChangePassword;
