import { StyleSheet, Text, View } from 'react-native';
import { API_URL } from '@env';
import AuthForm from 'jwt-auth-mongodb/dist/fe/component/native/AuthForm';
import { useEffect, useState } from 'react';
import BoardList from '../components/BoardList';

const User = ({
  loggedUser,
  setLoggedUser,
  storeUserData,
  clearUserData,
  boards,
  setBoards,
}) => {
  useEffect(() => {
    const url = API_URL + '/.netlify/functions/boards/';
    const loadBoards = async () => {
      try {
        const response = await fetch(url, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'x-access-token': loggedUser?.token,
          },
        });
        if (response.ok) {
          const boardList = await response.json();
          setBoards(boardList);
        } else {
          if (response.status === 401) {
            setLoggedUser(null);
          }
          console.log("Can't load data. Out " + response.status);
        }
      } catch (error) {
        console.log("Can't load data: " + error);
      }
    };
    if (loggedUser) {
      loadBoards();
    }
  }, [loggedUser]);

  return (
    <View style={styles.container}>
      <View style={styles.auth}>
        <AuthForm
          loggedUser={loggedUser}
          setLoggedUser={setLoggedUser}
          storeUserData={storeUserData}
          clearUserData={clearUserData}
          apiUrl={API_URL}
          styleTemplate={{
            // TODO fix strange behavior on mobile
            main: loggedUser ? stylesFormLoggedIn : stylesFormNotLogged,
            signInMobile: stylesSignInMobile,
            signUpMobile: stylesSignUpMobile,
          }}
        />
      </View>
      {loggedUser && (
        <View style={styles.boards}>
          <BoardList boards={boards} loggedUser={loggedUser} />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: 'auto',
  },
  auth: { height: 'auto' },
  boards: {
    flex: 1,
  },
});

const stylesFormLoggedIn = StyleSheet.create({
  container: {
    height: 'auto', //TODO - fix in library
  },
  tab: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

const stylesFormNotLogged = StyleSheet.create({
  container: {
    height: '100%', //TODO - fix in library
  },
  tab: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

const stylesSignInMobile = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  fieldView: {
    width: '100%',
    alignItems: 'center',
  },
  fieldWrapper: {
    backgroundColor: '#3BF',
    borderRadius: 30,
    width: '70%',
    height: 45,
    marginTop: 20,

    alignItems: 'center',
  },
  input: {
    height: 50,
    flex: 1,
    padding: 10,
    textAlign: 'center',
    width: '100%',
    color: 'white',
  },
  buttonArea: {
    flexDirection: 'column',
    paddingTop: 20,
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
  },
});

const stylesSignUpMobile = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  fieldView: {
    width: '100%',
    alignItems: 'center',
  },
  fieldWrapper: {
    backgroundColor: '#3BF',
    borderRadius: 30,
    width: '70%',
    height: 45,
    marginTop: 20,

    alignItems: 'center',
  },
  input: {
    height: 50,
    flex: 1,
    padding: 10,
    textAlign: 'center',
    width: '100%',
    color: 'white',
  },
  buttonArea: {
    flexDirection: 'column',
    paddingTop: 20,
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
  },
});

export default User;
