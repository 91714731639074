import { useNavigation } from '@react-navigation/native';
import { Text, View } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import Button from '../components/Button';
import ButtonBar from '../components/ButtonBar';

const Home = () => {
  const navigation = useNavigation();

  return (
    <View
      style={{
        flex: 1,
        padding: 20,
      }}
    >
      <View style={{ alignSelf: 'center' }}>
        <Text style={{ fontSize: 28, padding: 20 }}>Boardito</Text>
      </View>
      <ButtonBar>
        <Button title="Scan" onPress={() => navigation.navigate('Scanner')} />
        {/* <Button title="Filter" onPress={() => navigation.navigate('Filter')} /> */}
        <Button
          title="Create new"
          onPress={() => navigation.navigate('RegisterBoard')}
        />
        <Button title="User" onPress={() => navigation.navigate('User')} />
      </ButtonBar>
      <StatusBar style="auto" />
    </View>
  );
};

export default Home;
