import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { StyleSheet, Text, TextInput, View } from 'react-native';
import defaultTranslator from 'jwt-auth-mongodb/dist/fe/util/defaultTranslator';
import { isWeb } from 'jwt-auth-mongodb/dist/fe/util/util';
import Button from '../components/Button';
import ButtonBar from '../components/ButtonBar';
import { API_URL } from '@env';
import { useNavigation } from '@react-navigation/native';
import { resetPassword } from 'jwt-auth-mongodb/dist/fe/util/auth';

const ResetPassword = ({ t = defaultTranslator }) => {
  const navigation = useNavigation();
  const [resetError, setResetError] = useState(null);

  const validationSignIn = Yup.object().shape({
    email: Yup.string()
      .email(t('components.authForm.emailInvalid'))
      .required(t('components.authForm.required')),
  });

  const resetPasswordHandler = async (values) => {
    try {
      const userData = await resetPassword(
        values.email,
        setResetError,
        API_URL,
        t
      );
      if (userData) {
        setResetError(
          'Email sent. Please check your email and follow the instructions.'
        );
        console.log('Email sent.');
        navigation.navigate('ChangePassword');
      } else {
        setResetError(t('lib.auth.reset.cantReset'));
        return false;
      }
    } catch (e) {
      setResetError(t('lib.auth.reset.cantReset'));
      return false;
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validateOnChange={false}
      validationSchema={validationSignIn}
      onSubmit={resetPasswordHandler}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        touched,
        values,
        errors,
      }) => (
        <View style={styles.container}>
          <View style={styles.fieldView}>
            <View style={styles.fieldWrapper}>
              {isWeb() && <Text>{t('components.authForm.email')}</Text>}
              <TextInput
                style={styles.input}
                onBlur={handleBlur('email')}
                value={values.email}
                placeholder="Enter email"
                placeholderTextColor={isWeb() ? 'black' : 'white'}
                autoComplete="off"
                onChangeText={handleChange('email')}
              />
            </View>
            {errors.email && touched.email ? (
              <Text style={styles.errorMessage}>{errors.email}</Text>
            ) : null}
          </View>
          <View style={styles.buttonArea}>
            {resetError && (
              <Text style={styles.errorMessage}>{resetError}</Text>
            )}
            <ButtonBar>
              <Button
                title={t('components.authForm.resetPassword')}
                onPress={handleSubmit}
              />
            </ButtonBar>
          </View>
        </View>
      )}
    </Formik>
  );
};

const styles = isWeb()
  ? StyleSheet.create({
      container: {
        height: '100%',
        flex: 1,
        alignItems: 'center',
      },
      fieldView: {},
      fieldWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 20,
      },
      input: {
        borderWidth: 1,
        marginLeft: 20,
        paddingLeft: 5,
      },
      buttonArea: {
        flexDirection: 'column',
        paddingTop: 20,
      },
      errorMessage: {
        color: 'red',
        textAlign: 'center',
      },
    })
  : // mobile styles
    StyleSheet.create({
      container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      fieldView: {
        width: '100%',
        alignItems: 'center',
      },
      fieldWrapper: {
        backgroundColor: '#3BF',
        borderRadius: 30,
        width: '70%',
        height: 45,
        marginTop: 20,

        alignItems: 'center',
      },
      input: {
        height: 50,
        flex: 1,
        padding: 10,
        textAlign: 'center',
        width: '100%',
        color: 'white',
      },
      buttonArea: {
        flexDirection: 'column',
        paddingTop: 20,
      },
      errorMessage: {
        color: 'red',
        textAlign: 'center',
      },
    });

export default ResetPassword;
