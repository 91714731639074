import React from 'react';
import { StyleSheet } from 'react-native';
import { View } from 'react-native';

const ButtonBar = (props) => {
  const children = React.Children.toArray(props.children);
  const enabledChildren = children.filter((ch) => !ch.props.disabled);
  const disabledChildren = children.filter((ch) => !ch.props.disabled);
  return <View style={styles.buttonBar}>{props.children}</View>;
};

const styles = StyleSheet.create({
  buttonBar: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    flexWrap: 'wrap',
    gap: 10,
  },
});

export default ButtonBar;
